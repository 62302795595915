<template>
  <v-card>
    <v-card-title class="justify-content-between">
      <v-row class="mt-2">
        <v-autocomplete
          v-model="userId"
          :items="listOfEmployees"
          :loading="isLoadingEmployees"
          item-text="name"
          item-value="id"
          label="Selecionar usuário"
          class="ml-4"
          clearable
          outlined
          dense
        >
          <template
            v-slot:no-data
          >
            <p class="d-flex justify-center align-center pt-0 pb-0">
              Buscando dados...
            </p>
          </template>
        </v-autocomplete>

        <v-btn
          color="warning"
          class="ml-8"
          outlined
          @click="getItemsTable()"
        >
          <v-icon class="mr-2">
            {{ icons.mdiFilterPlus }}
          </v-icon>
          Pesquisar
        </v-btn>

        <v-spacer />

        <v-btn
          color="info"
          class="mr-2"
          outlined
          @click="openUserAccessModal()"
        >
          <v-icon class="mr-1">
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Atribuir sistemas
        </v-btn>
      </v-row>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="isLoading"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <h3 class="color-info">
          Selecione um usuário para ver a lista de sistemas atribuídos
        </h3>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-icon
          medium
          class="me-2"
          color="error"
          @click="deleteRegister(endpointDelete, item.user_system_id)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ !item.created_at ? '' : dateFormat(item.created_at) }}
      </template>

      <template v-slot:item.updated_at="{ item }">
        {{ !item.updated_at ? '' : dateFormat(item.updated_at) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showUserAccessModal"
      persistent
      width="700px"
    >
      <userAccessModal
        :systems-list="systemsList"
        :list-of-employees="listOfEmployees"
        @updatedTable="updatedTable()"
        @close="showUserAccessModal = false"
      ></userAccessModal>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiFilterPlus, mdiPlaylistPlus } from '@mdi/js'
import userAccessModal from './userAccessModal.vue'

export default {
  components: {
    userAccessModal,
  },

  mixins: [formatters, messages],

  data() {
    return {
      userId: '',
      userDetails: [],
      systemsList: [],
      listOfEmployees: [],

      endpointDelete: 'api/v1/records/user_system/destroy',

      headers: [
        {
          text: 'SISTEMA',
          value: 'name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'DATA DE CRIAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'ULTIMA ATUALIZAÇÃO',
          value: 'updated_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EXCLUIR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],

      isLoading: false,
      isLoadingEmployees: false,
      showUserAccessModal: false,
      showUserAccessDetails: false,

      icons: {
        mdiPlaylistPlus,
        mdiFilterPlus,
        mdiDelete,
      },
    }
  },

  created() {
    this.getListOfEmployees()
    this.getSystemList()
  },

  methods: {
    async getItemsTable() {
      if (!this.userId) {
        return
      }

      this.isLoading = true
      this.itemsTable = []

      await axiosIns.get(`/api/v1/records/user/edit/${this.userId}`).then(response => {
        response.data.data.user_system.forEach(({ system, id }) => {
          system.forEach(({
            system: systemName, created_at: createdAt, updated_at: updatedAt, id: systemId,
          }) => {
            this.itemsTable.push({
              id: systemId,
              user_system_id: id,
              name: systemName,
              created_at: createdAt,
              updated_at: updatedAt,
            })
          })
        })
      })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoading = false })
    },

    async getSystemList() {
      await axiosIns.get('api/v1/records/system/index').then(response => {
        this.systemsList = response.data.data
      }).catch(error => this.showErrorMessage(error))
    },

    async getListOfEmployees() {
      this.isLoadingEmployees = true

      await axiosIns.get('api/v1/records/user/index').then(response => {
        this.listOfEmployees = response.data.data
      })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoadingEmployees = false })
    },

    showErrorMessage(error) {
      this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message })
    },

    updatedTable() {
      if (this.itemsTable) {
        this.getItemsTable()
      }
    },

    openUserAccessModal() {
      this.showUserAccessModal = true
    },

    showFilterError() {
      this.showMessage({
        icon: 'warning',
        title: 'Filtro vazio!',
        text: 'Por favor, selecionar usuário!',
      })
    },
  },
}
</script>
