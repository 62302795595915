import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-content-between"},[_c(VRow,{staticClass:"mt-2"},[_c(VAutocomplete,{staticClass:"ml-4",attrs:{"items":_vm.listOfEmployees,"loading":_vm.isLoadingEmployees,"item-text":"name","item-value":"id","label":"Selecionar usuário","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',{staticClass:"d-flex justify-center align-center pt-0 pb-0"},[_vm._v(" Buscando dados... ")])]},proxy:true}]),model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}),_c(VBtn,{staticClass:"ml-8",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.getItemsTable()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlus)+" ")]),_vm._v(" Pesquisar ")],1),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openUserAccessModal()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Atribuir sistemas ")],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h3',{staticClass:"color-info"},[_vm._v(" Selecione um usuário para ver a lista de sistemas atribuídos ")])]},proxy:true},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.user_system_id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!item.created_at ? '' : _vm.dateFormat(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!item.updated_at ? '' : _vm.dateFormat(item.updated_at))+" ")]}}])}),_c(VDialog,{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.showUserAccessModal),callback:function ($$v) {_vm.showUserAccessModal=$$v},expression:"showUserAccessModal"}},[_c('userAccessModal',{attrs:{"systems-list":_vm.systemsList,"list-of-employees":_vm.listOfEmployees},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showUserAccessModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }