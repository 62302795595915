<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Atribuir sistemas
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
          v-model="selectedUserId"
          :items="listOfEmployees"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          label="Selecione o usuário"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="idOfSelectedSystems"
          :items="systemsList"
          :rules="[rules.required]"
          item-text="system"
          item-value="id"
          label="Selecione os sistemas"
          clearable
          multiple
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    systemsList: {
      type: [Array, Object],
      required: true,
    },

    listOfEmployees: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      selectedUserId: '',
      isLoading: false,

      idOfSelectedSystems: [],
    }
  },

  methods: {
    async sendModalData() {
      const formIsValid = this.$refs.form.validate() && this.idOfSelectedSystems.length > 0
      const { selectedUserId, idOfSelectedSystems } = this

      const body = {
        users_id: selectedUserId,
        systems_id: idOfSelectedSystems,
      }

      if (!formIsValid) {
        this.showMessage({
          icon: 'warning',
          title: 'Formulário vazio!',
          text: 'Por favor, preencher todos os dados!',
        })

        return
      }

      this.isLoading = true

      await axiosIns
        .post('/api/v1/records/user_system/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.resetForm()
          this.closeModal()
        })
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>
