import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-2"},[_vm._v(" Atribuir sistemas ")]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VAutocomplete,{attrs:{"items":_vm.listOfEmployees,"rules":[_vm.rules.required],"item-text":"name","item-value":"id","label":"Selecione o usuário","clearable":"","outlined":"","dense":""},model:{value:(_vm.selectedUserId),callback:function ($$v) {_vm.selectedUserId=$$v},expression:"selectedUserId"}}),_c(VAutocomplete,{attrs:{"items":_vm.systemsList,"rules":[_vm.rules.required],"item-text":"system","item-value":"id","label":"Selecione os sistemas","clearable":"","multiple":"","outlined":"","dense":""},model:{value:(_vm.idOfSelectedSystems),callback:function ($$v) {_vm.idOfSelectedSystems=$$v},expression:"idOfSelectedSystems"}})],1),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){_vm.closeModal(), _vm.resetForm()}}},[_vm._v(" Fechar ")]),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoading)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }